import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Природний Рай
			</title>
			<meta name={"description"} content={" Наш затишний готель, розташований серед мальовничих краєвидів, пропонує вам спокійний відпочинок, який поєднує в собі йогу, спа-процедури та вегетаріанську кухню."} />
			<meta property={"og:title"} content={"Природний Рай"} />
			<meta property={"og:description"} content={" Наш затишний готель, розташований серед мальовничих краєвидів, пропонує вам спокійний відпочинок, який поєднує в собі йогу, спа-процедури та вегетаріанську кухню."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text" />
			<Override slot="text1">
				вул. Ділянка Щівки, Polyanytsya, Ivano-Frankivsk Oblast, 78593
			</Override>
			<Override slot="text2" />
			<Override slot="link">
				0667778777
			</Override>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="box3" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="box" />
		</Components.Header23>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 2rem 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					<Em>
						Відкрийте гармонію з природою
					</Em>
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Ласкаво просимо до Природного Раю – вашого ідеального місця для відновлення душі та тіла. Наш затишний готель, розташований серед мальовничих краєвидів, пропонує вам спокійний відпочинок, який поєднує в собі йогу, спа-процедури та вегетаріанську кухню.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11%3A16%3A53.828Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11:16:53.833Z"
							srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out12.jpg?v=2024-06-10T11%3A16%3A53.833Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11:16:53.831Z"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out5.jpg?v=2024-06-10T11%3A16%3A53.831Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11:16:53.830Z"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/IMG_4839.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11:16:53.838Z"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/lada1.jpeg?v=2024-06-10T11%3A16%3A53.838Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text margin="0px 0px 1rem 0px" font="--headline5" color="--light">
					У Природному Раю ми віримо в силу природи та її здатність відновлювати. Наші гості можуть насолоджуватися різноманітними оздоровчими програмами, медитацією та здоровим харчуванням. Ми створили унікальне місце, де кожен знайде своє натхнення.
				</Text>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
					background="--color-darkL2"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Наші Номери
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Наші номери облаштовані з любов'ю до деталей, щоб забезпечити максимальний комфорт і затишок. Кожен номер має вид на мальовничі краєвиди, що сприяє відновленню та релаксації.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11:16:53.830Z"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					max-height="100vh"
					srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/out14.jpg?v=2024-06-10T11%3A16%3A53.830Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Йога Програми
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Приєднуйтесь до наших йога-класів, які підходять для всіх рівнів підготовки. Досвідчені інструктори допоможуть вам знайти гармонію тіла та душі через асани, медитацію та дихальні практики.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11:16:53.852Z"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					order="-1"
					max-height="100vh"
					srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/noroot.png?v=2024-06-10T11%3A16%3A53.852Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
					background="--color-darkL2"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Вегетаріанська Кухня
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Наша кухня пропонує лише свіжі та органічні продукти. Ми створюємо страви, які не лише смачні, але й корисні для вашого здоров'я. Насолоджуйтесь багатством смаків, приготованих нашими шеф-кухарями.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					max-height="100vh"
					srcSet="https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1498601761256-9e93c6f5c181?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Спа Процедури
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Розслабтесь та поновіть свої сили завдяки нашим спа-процедурам. Ми пропонуємо масажі, ароматерапію та інші процедури, які допоможуть вам відновити баланс та енергію.
						</Text>
						<Button background="--color-darkL1" type="link" href="/about" text-decoration-line="initial">
							Дізнатися більше
						</Button>
					</Box>
				</Box>
				<Image
					src="https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					order="-1"
					max-height="100vh"
					srcSet="https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer1>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer1>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});